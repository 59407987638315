// for custom Bootstrap settings

$header-bg-color-1: #707070;
$header-bg-color-2: #0e0e0e;

$footer-bg-color: #2d2d2e;

$menu-bg-color: #707070;
$menu-dropdown-bg-color-1: #ddd;
$menu-dropdown-bg-color-2: #06bff3;

$main-bg-color: #f6f6f6;
$main-button-color: #06bff3;
$main-email-color: #0e0e0e;
$main-highlight-color-1: #795c5c;
$main-highlight-color-2: #06bff3;
$main-icon-color: #06bff3;
$main-link-color: #1a00ed;
$main-phone-color: #06bff3;
$main-text-color: #707070;
$main-text-dark-color: #000;
$main-text-light-color: #fff;

$search-bg-color: #eee;

$btn-font-weight: 600;

$breadcrumb-bg: $header-bg-color-1;
$breadcrumb-divider: quote("»");
$breadcrumb-divider-color: $main-text-light-color;
$breadcrumb-padding-x: 0;
$breadcrumb-padding-y: 0;

$appVersionZindex: 2000;
$dayPickerZIndex: 10000;
$tableHeaderZindex: 1;
$tableBodyZindex: 0;
$thTdResizerZindex: 1;
$headerNavZindex: 101;
