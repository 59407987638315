.user-panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  margin: 100px 0;
}

.user-panel-header {
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 20px;
}
