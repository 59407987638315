@import 'custom';

.fontIcon {
  font-size: 2.2em;
  color: $main-text-light-color;
}
.fontIconDark {
  font-size: 2.2em;
  color: $main-text-color;
}
.form-check-input {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  position: relative;
}
.user-panel {
  display: flex;
  flex-grow: 1;
  align-items: center;
  height: 72px;
  cursor: pointer;

  padding: 0.3rem 0.6rem;
}
.user-panel2 {
  display: flex;
  flex-grow: 2;
  align-items: center;
  height: 72px;

  padding: 0.3rem 0.6rem;
  & p {
    margin: 0;
  }
}
.user-panel3 {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0.3rem 0.6rem;
  & p {
    margin: 0;
  }
}

.admin-panel-no-table,
.search-narrow-results {
  margin-top: 4rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.wrappedText {
  white-space: normal;
  word-break: break-all;
}

.displayBlock {
  display: block;
  max-height: 49vh;
}

.listgroup-item-lower {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}

.card {
  border-radius: 0;
}

.btn {
  max-height: 2.5em;
  vertical-align: baseline;
  border-radius: 0;
  color: $main-text-dark-color;
}

.btn:hover {
  color: $main-text-light-color;
}

a:hover {
  text-decoration: none;
}

.hoverClickHand:hover {
  cursor: pointer;
}

.ik2-lightest-bg-color {
  background-color: $main-text-light-color;
  color: $main-highlight-color-1;
}

.bottom-menu-add-update-data {
  background-color: rgba(255, 255, 255, 0.6);
  bottom: 0;
  padding: 1rem;
  position: fixed;
  width: 85%;
  z-index: 200;
}

.bottom-menu-buttons {
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.6);
  position: fixed;
  z-index: 300;
}

.col-with-togglecontainer {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}

.ik2-main-button-color,
.ik2-main-button-color:visited,
.ik2-main-button-color:link {
  color: $main-text-dark-color;
}

.ik2-main-button-color:active,
.ik2-main-button-color:hover {
  color: $main-text-light-color;
}

.ik2-bg-color,
.ik2-bg-color:active,
.ik2-bg-color:hover,
.ik2-bg-color:link,
.ik2-bg-color:visited {
  background-color: $menu-bg-color;
  border-color: $menu-bg-color;
  color: $main-text-light-color;
}

.ik2-link-inside-button,
.ik2-link-inside-button:hover,
.ik2-link-inside-button:active,
.ik2-link-inside-button:link {
  color: $main-text-dark-color;
  cursor: default;
}

.ik2-light-color-1,
.ik2-light-color-1:active,
.ik2-light-color-1:hover,
.ik2-light-color-1:link,
.ik2-light-color-1:visited {
  background-color: $header-bg-color-1;
  border-color: $header-bg-color-1;
  color: $main-text-light-color;
}

.ik2-light-color-2,
.ik2-light-color-2:active,
.ik2-light-color-2:hover,
.ik2-light-color-2:link,
.ik2-light-color-2:visited {
  background-color: $menu-dropdown-bg-color-1;
  border-color: $menu-dropdown-bg-color-1;
  color: $main-text-dark-color;
}

hr {
  border-width: 0.4vh;
}

input[type='file'] {
  display: none;
}

.notes-counter {
  background-color: $menu-dropdown-bg-color-1;
  color: $main-text-dark-color;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding: 0.2rem;
}

.nav-tabs {
  border: 0;
}

.clickableGroup {
  cursor: pointer;
}
// zbiorcze style dla elementów // M.P.

.input-group-prepend {
  margin: 0.03rem 0.06rem !important;
  padding: 0.1rem 0.2rem !important;
}
svg {
  margin: 0.03rem 0.06rem;
  padding: 0.15rem 0.3rem;
}
button {
  margin: 0.5rem 0.5rem;
  padding: 0.1rem 0.2rem;
}
#group-localNo {
  margin-top: 2rem;
}

.input-group-text {
  font-size: 0.875rem;
}

.input-radio-data-label {
  font-size: small;
  padding-top: 0.5rem;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}

//important for InputDate.tsx
.form-check {
  padding-left: 0rem;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

.input-file {
  background-color: $main-button-color;
  margin: 0;
}
