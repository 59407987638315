// for overwriting Bootstrap settings

@import 'custom';

$primary: $main-button-color;

.accordion {
  width: 100%;
}

.breadcrumb {
  @extend .breadcrumb;
  a {
    color: darken($main-text-light-color, 10);
  }

  a:hover {
    color: $main-text-light-color;
  }

}

.breadcrumb:hover {
  cursor: pointer;
}

.dropdown-menu {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.dropdown-menu a:hover {
  background-color : darken($main-text-light-color, 10);
  color : $main-highlight-color-1;
}

.nav > .active {
  font-weight: bold;
  color: $main-highlight-color-1;
}

.nav-link {
  color: $main-highlight-color-1;
}

.nav-link:hover {
  border: 0 !important;
}

select {
  border-radius: 0 !important;
}

.select-side {
  pointer-events: none;
}

.tab-content {
  width: 100%;
}

.modal-50w {
  max-width: 50% !important;
}

.modal-100w {
  max-width: 100% !important;
}
