@import 'styles/overwrite';

@import '~bootstrap/scss/bootstrap';

@import 'styles/commons';
@import 'styles/custom';

body {
  margin: 0;
  padding: 0;
  min-width: 100%;
  font-family: 'Fira Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
}

html {
  overflow-y: scroll;
  font-weight: 400;
}
.primary {
  font-weight: 600 !important;
}
