@import '../../../styles/custom.scss';

img {
    width: 1em;
  }

  .rdt_TableCell {
    border-right: 1px #ccc solid;
  }

  .rdt_TableCell > div, .rdt_TableCol > div, .rdt_TableCol_Sortable > div {
    margin: 0 auto;
    text-align: center;
  }


  .ik2-main-table-input {
    width: 200px;
    height: 32px;
    padding: 0 32px 0 16px;
    border: 1px solid #e5e5e5;
    border-radius: 5px 0 0 5px;

    &:hover {
      cursor: pointer;
    }
  }
  .placeAreaClass .list-group {
    padding-right: 0;
  }

  .table-container {
    .h6-table-title {
      color: #795c5c;
      margin-bottom: 0;
      margin-left: 0.5rem;
    }
    .h6-table-title-long {
      font-weight: bold;
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .table {
      border: 1px solid #ddd;
      .tr {
        :last-child {
          .td {
            border-bottom: 0;
          }
        }
      }
      .td {
        text-align: center;
        :nth-child(1) {
          border-right: 0;
        }
      }
      .th, .td {
        padding: 5px;
        border-bottom: 1px solid #ddd;
        border-right: 1px solid #ddd;
        background-color: #fff;
        overflow: hidden;
        text-align: center;
        :last-child {
          border-right: 0;
        }
        .resizer {
          display: inline-block;
          width: 5px;
          height: 100%;
          position: absolute;
          right: 0;
          top: 0;
          transform: translateX(50%);
          z-index: $thTdResizerZindex;
          &.isResizing {
            background: red;
          }
        }
      }
      .th {
        background: rgb(242,242,242);
        background: linear-gradient(180deg, rgba(242,242,242,1) 0%, rgba(242,242,242,1) 35%, rgba(235,235,235,1) 100%);
        color: #000;
        font-weight: bold;
        border-bottom: 0;
        border-right: 0;
      }
      &.sticky {
        overflow: scroll;
        .header {
          position: sticky;
          z-index: $tableHeaderZindex;
          width: fit-content;
          top: 0;
        }
        .body {
          position: relative;
          z-index: $tableBodyZindex;
        }
        [data-sticky-td] {
          position: sticky;
        }
      }
      .rowSelected {
        background: #E3F2FD;
      }
      .pagination {
        padding: 0.5rem;
      }
    }
    .search-input {
       border : 0;
       font-size: 1.1rem;
       :focus {
         outline: none;
       }
       &-table {
        border: 1px solid #d4d4d4;
        border-radius: 0.25rem;
        margin-right: 5px;
        padding: 0 3px;
       }
    }
  }

  .search-clear {
    cursor: pointer;
    display: block;
  }
