.infoSourceLabel {
  width: 180px;
}
select {
  border: none;
}

.proposed-places-title {
  margin: 5px 0 0 0;
  font-size: 20px;
  font-weight: 600;
}

.proposed-places-container {
  width: 100%;
  margin: 0 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex-end;
}

.proposed-places-button {
  width: min-content;
  //margin: 5px 0 5px auto;
  white-space: nowrap;
}

.proposed-place {
  margin: 2px 0;
}

.proposed-places-remove-button {
  margin: 0 0 0 5px;
  font-weight: 700;
  text-align: center;
  vertical-align: center;
  cursor: pointer;
}

.proposed-places-remove-button:hover {
  color: red;
}

