@import '../../styles/commons.scss';

.app-version {
  position: absolute;
  left: 0;
  top: 0;
  z-index: $appVersionZindex;
  margin: 5px;
  font-size: 12px;
  color: lightgray;
}
