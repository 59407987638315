@import '../../styles/custom.scss';

.archives-checkbox > .form-check-input {
  margin-right: 6rem;
  position: absolute;
}

.archives-checkbox > .form-check-label {
  padding-bottom: 15px;
  color: #e6e6e6;
}

#nav {
  padding: 0;
  top : 0;
  z-index : $headerNavZindex;
}

nav {
  display: flex !important;
}
nav .row {
  height: 72px;
  display: flex;
}
nav .row div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

nav .form-group {
  margin-top: 0;
  padding: 0;
}
nav label {
  margin: 1.2rem;
  min-width: 40px;
}
nav select {
  display: block;
  width: 260px;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #e6e6e6;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.menu-link-style:hover {
  background-color: #e6e6e6;
  color: #795c5c;
}

#header-archive-investment-stage-inputs {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width:600px) and (max-width: 1000px) {
  #header-first-row {
    height: 23rem;
  }
}

@media only screen and (max-width: 600px) {
  #header-first-row {
    height: 18rem;
  }
}

@media only screen and (max-width: 1000px) {
  #nav {
    position: relative;
  };
  #header-archive-investment-stage-inputs {
    display: flex;
    flex-direction: column;
  }
}
